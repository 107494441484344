// Charge la base commune
// import bundle from './form.module.js' // lazy-loading code splitting (se charge uniquement en cas de call lazy-loading like)
// import _ from 'lodash' // lazy-loading dynamic import (se charge uniquement en cas de call lazy-loading like)

// Styles librairies
import 'jquery-mask-plugin/dist/jquery.mask.min.js'
import 'jquery-validation/dist/jquery.validate.js'

require('./common.js')

// Styles custom
// import './sass/pages/search.scss'

// Scripts librairies
// import exec from 'imports-loader?$=jquery!script-loader!./js/libs/malihu-custom-scrollbar-plugin-master/jquery.mCustomScrollbar.concat.min.js'

// Pages
// var realisationsPage = require('exports-loader?realisationsPage!./js/pages/realisations.js')

// actions principales communes à toutes les pages
$(document).ready(function () {
  // masks
  $('.mask-1-uppercase').mask('U', { translation: { U: { pattern: /[A-Z]/ } } })
  $('.mask-2-uppercase').mask('UU', { translation: { U: { pattern: /[A-Z]/ } } })
  $('.mask-1-digit').mask('9')
  $('.mask-2-digits').mask('99')
  $('.mask-5-digits').mask('99999')
  $('.latitude').mask('D0Z.00ZZZZZZZZZ', { translation: { Z: { pattern: /[0-9]/, optional: true }, D: { pattern: /-/, optional: true } } })
  $('.longitude').mask('D0ZZ.00ZZZZZZZZ', { translation: { Z: { pattern: /[0-9]/, optional: true }, D: { pattern: /-/, optional: true } } })
  $('.mask-slug').mask('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', { translation: { L: { pattern: /[a-z-]/ } } })
  $('.mask-fullslug').mask('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', { translation: { L: { pattern: /[a-z-0-9-]/ } } })
  $('.mask-lowercase').mask('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', { translation: { L: { pattern: /[a-z]/ } } })
  $('.mask-digits').mask('999999999999999999999999999999999')
  $('.email').mask('A', {
    translation: {
      A: { pattern: /[\w@\-.+]/, recursive: true }
    }
  })
  $('.phone').mask('00-00-00-00-00')
  $('.money').mask('000000000', { reverse: true })

  // form validations
  $.each($('form[name="request"]'), function (i, form) {
    $(form).validate({
      errorPlacement: function (error, element) {
        error.insertAfter(element.parent())
      }
    })
  })

  // sur l'exécution d'une méthode, on l'affiche dans les résultats directement sur la page
  $('form[name="request"] button[name="execute"]').on('click', function (e) {
    e.preventDefault()
    const _form = $(this).parents('form')
    const _vars = []
    let _url = $(_form).attr('action')
    if (_form.valid()) {
      $.each(_form.find('input, textarea, select'), function (i, field) {
        if ($(field).val() !== null) {
          _vars.push($(field))
        }
      })
      switch ($(_form).attr('method')) {
        case 'get' : {
          let j = 0
          $.each(_vars, function (i, elem) {
            if ($(elem).val() !== '') {
              if (j === 0) { _url += '?' } else { _url += '&' }
              _url += $(elem).attr('name') + '=' + $(elem).val()
              j++
            }
          })
          // ajax avec envoi de fichiers
          if (typeof $(_form).attr('data-response-image') !== 'undefined' && $(_form).attr('data-response-image') !== false) {
            // https://stackoverflow.com/questions/1999607/download-and-open-pdf-file-using-ajax
            /*
            // ajax
            $.ajax({
              type: 'get',
              url: _url,
              headers: { 'x-api-key': '688849506afaf5f9c6cb473a935af6b63a68145c0b12ae44763da5b8a94e84a9' }, // sha256
              cache: false,
              xhrFields: {
                responseType: 'blob'
                //                //responseType: 'arraybuffer'
              },
              success: function (response) {
                //                const blob = new Blob([response], { type: 'application/pdf' })
                const blob = new Blob([response], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'test.pdf'
                link.click()
                $('#results .modal-body').html('<pre>' + link + '</pre>')
                $('#results').modal('show')
              }
            })
            */

            // https://stackoverflow.com/questions/34586671/download-pdf-file-using-jquery-ajax/34587987#34587987
            const req = new XMLHttpRequest()
            req.open('GET', _url, true)
            req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
            req.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            req.setRequestHeader('x-api-key', '688849506afaf5f9c6cb473a935af6b63a68145c0b12ae44763da5b8a94e84a9')
            req.responseType = 'blob'
            req.onload = function () {
              const blob = req.response
              const link = document.createElement('a')
              //              const fileheaders = req.getAllResponseHeaders()
              //              console.log(fileheaders)
              const timestamp = Math.floor(Date.now() / 1000)
              link.href = window.URL.createObjectURL(blob)
              link.download = 'test' + timestamp + '.pdf'
              link.click()
            }
            req.send()
          } else {
            // ajax
            $.ajax({
              type: 'get',
              dataType: 'json',
              url: _url,
              headers: { 'x-api-key': '688849506afaf5f9c6cb473a935af6b63a68145c0b12ae44763da5b8a94e84a9' }, // sha256
              success: function (response) {
                // console.log(response)
                response = JSON.stringify(response, null, 2)
                $('#results .modal-body').html('<pre>' + response + '</pre>')
                $('#results').modal('show')
              }
            })
          }
          break
        }
        case 'post' : {
          let datas = {}
          $.each(_vars, function (i, elem) {
            const n = $(elem).attr('name')
            datas[n] = $(elem).val()
          })
          // ajax avec envoi de fichiers
          if ($(_form).attr('enctype') === 'multipart/form-data') {
            //            var f_datas = new FormData()
            //            $.each($(_form).find('input[type="file"]')[0].files, function(i, file) {
            //              f_datas.append('file-'+i, file)
            //            })
            //            datas = $.extend({}, datas, f_datas)
            datas = new FormData($(_form)[0])
            //            $.each($(_form).find('input[type="file"]')[0].files, function(i, file) {
            //              console.log(file)
            //              datas.append('files[]', file)
            //            })
            $.ajax({
              type: 'post',
              dataType: 'json',
              url: _url,
              data: datas,
              cache: false,
              contentType: false,
              processData: false,
              headers: { 'x-api-key': '688849506afaf5f9c6cb473a935af6b63a68145c0b12ae44763da5b8a94e84a9' }, // sha256
              success: function (response) {
                // console.log(response)
                response = JSON.stringify(response, null, 2)
                $('#results .modal-body').html('<pre>' + response + '</pre>')
                $('#results').modal('show')
              }
            })
          } else { // ajax standard
            $.ajax({
              type: 'post',
              dataType: 'json',
              url: _url,
              data: datas,
              headers: { 'x-api-key': '688849506afaf5f9c6cb473a935af6b63a68145c0b12ae44763da5b8a94e84a9' }, // sha256
              success: function (response) {
                // console.log(response)
                response = JSON.stringify(response, null, 2)
                $('#results .modal-body').html('<pre>' + response + '</pre>')
                $('#results').modal('show')
              }
            })
          }
          break
        }
      }
    }
  })

  // sur le go d'une méthode, on redirige vers l'url complète
  $('form[name="request"] button[name="go"]').on('click', function (e) {
    e.preventDefault()
    const _form = $(this).parents('form')
    const _vars = []
    let _url = $(_form).attr('action')
    if (_form.valid()) {
      $.each(_form.find('input, textarea, select'), function (i, field) {
        if ($(field).val() !== null) {
          _vars.push($(field))
        }
      })
      switch ($(_form).attr('method')) {
        case 'get' : {
          let j = 0
          $.each(_vars, function (i, elem) {
            if ($(elem).val() !== '') {
              if (j === 0) { _url += '?' } else { _url += '&' }
              _url += $(elem).attr('name') + '=' + $(elem).val()
              j++
            }
          })
          document.location = _url
          break
        }
        case 'post' : {
          _form.submit()
          break
        }
      }
    }
  })
})
