/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
import 'bootstrap/scss/bootstrap'

// Styles template
// import 'scss/style.scss'

// Styles librairies
// import 'toastr/toastr.scss'

// Styles custom
import './scss/custom' // directement déclaré dans le fichier de config webpack base
// import 'lazysizes' // lazy loading https://github.com/aFarkas/lazysizes
$('body').removeClass('no-js')

// Plugins template
// import 'script-loader!js/plugins.js'

// Scripts librairies
// import 'toastr/toastr.js'

// Actions principales communes à toutes les pages
/*
$(document).ready(function() {

})
*/
